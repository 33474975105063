@import url('//fonts.googleapis.com/css?family=IBM Plex Sans Condensed');

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


html,
body,
ul {
  padding: 0;
  margin: 0;
  width: 100%;
  background-color: #DFE4FB;
  height: 100vh;
  font-family: 'IBM Plex Sans Condensed', sans-serif;
  max-width: 100%;
  overflow-x: hidden;
}